import { useRef, useState, useEffect } from "react";
import { DotLottiePlayer } from '@dotlottie/react-player';
import Pusher from 'pusher-js';
import React from 'react'
import ReactPlayer from 'react-player/youtube'
import '@dotlottie/react-player/dist/index.css';
import "./App.css";

const App = () => {

  const pusher = new Pusher('afcba34ed598af3ffcea', {
    cluster: 'eu',
  });

  const channel = pusher.subscribe('scoreboard');

  const [home, setHome] = useState("");
  const [away, setAway] = useState("");
  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);
  const [time, setTime] = useState("00:00");

  const [screen, setScreen] = useState("scoreboard");

  channel.bind('setup', function (data) {
    const player = document.getElementById('player');
    player.querySelector('#home > text').innerHTML = data.home;
    player.querySelector('#away > text').innerHTML = data.away;
    player.querySelector('#home-score > text').innerHTML = data.homeScore;
    player.querySelector('#away-score > text').innerHTML = data.awayScore;
    player.querySelector('#time > text').innerHTML = data.time;
  });

  channel.bind('screen', function (data) {
    setScreen(data.show);
  });

  if (screen == 'scoreboard') {
    return (
      <DotLottiePlayer
        id="player"
        src="/scoreboard-v21.json"
        autoplay
        loop
      >
      </DotLottiePlayer>
    );
  } else if (screen == 'goal') {
    return (
      <div>James goal animation + video of goal</div>
    )
  }

};

export default App;
